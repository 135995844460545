import { useCallback, useEffect, useReducer, useState } from 'react';

import isArray from 'lodash/isArray';
import useSWR from 'swr';

import { AccountProps } from 'components/shared/AccountConnector';

import { doBFFDelete, doGet } from '../../../components/api';
import { Listing } from '../../../declarations/exclusives/listing';
import { getListingWithComputedProperties } from '../../../helpers/exclusives/listing';
import { EXCLUSIVE_RAILS_HOST } from '../../globals';

export const WATCHLIST_LOCAL_STORAGE_KEY = '@cosmos/exclusives/watchlist';

function getListingWatchlistLSKey(listingId: Listing['id']) {
  return `${WATCHLIST_LOCAL_STORAGE_KEY}/${listingId}`;
}

function getListingIsOnWatchlistAtLS(listingId: Listing['id']): boolean {
  const str = localStorage.getItem(getListingWatchlistLSKey(listingId));
  return !!str && JSON.parse(str);
}

function setListingIsOnWatchlistAtLS(listingId: Listing['id'], isOnWatchlist: boolean) {
  const key = getListingWatchlistLSKey(listingId);
  if (isOnWatchlist) {
    localStorage.setItem(key, JSON.stringify(true));
  } else {
    localStorage.removeItem(key);
  }
}

function updateListingsOnWatchlistLS(isOnWatchlist: boolean, ...listings: Listing[]) {
  for (const listingId of listings.map(({ id }) => id)) {
    setListingIsOnWatchlistAtLS(listingId, isOnWatchlist);
  }
}

function clearListingsOnWatchlistLS() {
  for (const key in localStorage) {
    if (key.startsWith(WATCHLIST_LOCAL_STORAGE_KEY)) {
      localStorage.removeItem(key);
    }
  }
}

const WATCHLIST_PATH = '/api/v1/exclusives/watchlist';

class WatchlistFetchingError extends Error {
  constructor(readonly response: Response, readonly error?: unknown) {
    super(`WatchlistFetcherError: response statsuCode ${response.status}`);
  }
}

async function watchlistFetcher() {
  const response = await doGet(WATCHLIST_PATH, { retries: 0 });
  let body: Listing[] | unknown;
  if (response.ok) {
    body = await response.json();
    if (isArray(body)) {
      return body.map(getListingWithComputedProperties);
    }
  }
  throw new WatchlistFetchingError(response, body);
}

export function useListingsOnWatchlist(
  isAuthenticated: boolean,
  { account }: { account?: AccountProps } = {},
) {
  const {
    data: listings,
    isLoading,
    error,
    mutate,
  } = useSWR(isAuthenticated && WATCHLIST_PATH, watchlistFetcher, {
    onSuccess: (listings) => {
      if (listings) {
        clearListingsOnWatchlistLS();
        updateListingsOnWatchlistLS(true, ...listings);
      }
    },
  });
  const isError = !!error && !isLoading;
  // Cleanup of watchlist data if unauthenticated
  useEffect(() => {
    if (account) {
      if (!account.infoIsLoading && !account.loggedIn) {
        clearListingsOnWatchlistLS();
      }
    }
  }, [account?.infoIsLoading, account?.loggedIn]);
  return { listings, isError, isLoading, mutate };
}

export type AddedToWatchlistSource =
  | 'pdp_favorite'
  | 'pdp_get_email_updates'
  | 'pdp_start_an_offer'
  | 'pdp_start_tour_request'
  | 'pdp_start_self_tour_schedule'
  | 'saved-homes_favorite'
  | 'gallery_favorite'
  | 'gallery_get_email_updates'
  | 'in_home_tour_sellers_disclosure_request'
  | 'self-unlock_get_email_updates'
  | 'available-soon-signage-flow_get_email_updates'
  | 'self-unlock_swipe';

async function addToWatchlistMutator(
  listingId: Listing['id'],
  { email, source }: { email: string; source?: AddedToWatchlistSource },
) {
  const resp = await fetch(`${EXCLUSIVE_RAILS_HOST}/api/v1/exclusives/watchlist`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ listing_id: listingId, email, source }),
  });
  const body: WatchlistResponse = await resp.json();
  const wasAddedOrIsAlreadyOnWatchlist =
    resp.ok || (resp.status === 422 && body?.errors.includes('already exists'));
  if (!wasAddedOrIsAlreadyOnWatchlist) {
    throw new Error('Add to watchlist failed!');
  }
  setListingIsOnWatchlistAtLS(listingId, true);
}

async function removeFromWatchlistMutator(listingId: Listing['id'], { email }: { email: string }) {
  const resp = await doBFFDelete(`${EXCLUSIVE_RAILS_HOST}/api/v1/exclusives/watchlist`, {
    listing_id: listingId,
    email,
  });
  const wasRemovedFromWatchlist = resp.ok || resp.status === 404;
  if (!wasRemovedFromWatchlist) {
    throw new Error('Removal form watchlist failed!');
  }
  setListingIsOnWatchlistAtLS(listingId, false);
}

export default function useWatchlist(
  listing: Listing,
  {
    onRemovedFromWatchlist,
    onAddedToWatchlist,
    onError,
    source,
    isListingOnWatchlist = false,
  }: UseWatchlistOptions = {},
) {
  const [isOnWatchlist, setIsOnWatchlist] = useState<boolean>(isListingOnWatchlist);
  const [isLoading, toggleLoading] = useReducer((s) => !s, false);
  const [error, setError] = useState<unknown>();

  const updateIsListingOnWatchlist = useCallback(
    (isOnWatchlist: boolean) => {
      setIsOnWatchlist(isOnWatchlist);
      updateListingsOnWatchlistLS(isOnWatchlist, listing);
    },
    [listing],
  );

  const addToWatchlist = useCallback(
    async (
      email: string,
      { source: overridenSource }: { source?: UseWatchlistOptions['source'] } = {},
    ) => {
      toggleLoading();
      try {
        await addToWatchlistMutator(listing.id, {
          email,
          source: overridenSource || source,
        });
        updateIsListingOnWatchlist(true);
        onAddedToWatchlist?.();
        toggleLoading();
      } catch (e) {
        onError?.(e);
        setError(e);
        toggleLoading();
        throw e;
      }
    },
    [listing.id],
  );

  const removeFromWatchlist = useCallback(
    async (email: string) => {
      toggleLoading();
      try {
        await removeFromWatchlistMutator(listing.id, { email });
        updateIsListingOnWatchlist(false);
        onRemovedFromWatchlist?.();
      } catch (e) {
        onError?.(e);
        setError(e);
      } finally {
        toggleLoading();
      }
    },
    [listing.id],
  );

  const toggleWatchlist = useCallback(isOnWatchlist ? removeFromWatchlist : addToWatchlist, [
    listing.id,
    isOnWatchlist,
  ]);

  useEffect(() => {
    setError(undefined);
  }, [isListingOnWatchlist]);
  useEffect(() => {
    updateIsListingOnWatchlist(isListingOnWatchlist || getListingIsOnWatchlistAtLS(listing.id));
  }, [listing, isListingOnWatchlist]);

  return {
    isOnWatchlist,
    isLoading,
    setIsOnWatchlist: updateIsListingOnWatchlist,
    addToWatchlist,
    removeFromWatchlist,
    toggleWatchlist,
    error,
    source,
  };
}

type WatchlistResponse = Record<string, never> | { errors: string };

interface UseWatchlistOptions {
  onRemovedFromWatchlist?: () => void;
  onAddedToWatchlist?: () => void;
  onError?: (e: unknown) => void;
  isListingOnWatchlist?: boolean;
  source?: AddedToWatchlistSource;
}
